import React from "react";
import Container from "../components/Container";
import { gettingStartedPageContent } from "../utils/constant";
import RenderPageData from "../components/RenderPageData";
import { Helmet, HelmetProvider } from "react-helmet-async";

const GettingStarted = () => {
	const pageTitle = "Getting Started With SavedBy";

	return (
		<Container>
			<HelmetProvider>
				<Helmet>
					<title>{pageTitle}</title>
				</Helmet>
			</HelmetProvider>
			<RenderPageData pageTitle={pageTitle} pageData={gettingStartedPageContent} />
		</Container>
	);
};

export default GettingStarted;
