import { useRoutes, Navigate } from "react-router-dom";
import React from "react";
import Layout from "./layouts/Docs/Layout";
import {
	BillingPage,
	WidgetPage,
	GettingStartedPage,
	ClaimsPage,
	InstallingPage,
	MerchantDashboardPage,
	ExcludeProductsPage,
	KlaviyoIntegrationPage,
	CustomerFAQPage,
} from "./pages";

const Routes = () => {
	return useRoutes(routesConfig);
};

export default Routes;

/**
 * @type {import("react-router-dom").RouteObject[]}
 */
const routesConfig = [
	{
		path: "/",
		element: <Layout />,
		children: [
			{
				path: "/",
				element: <GettingStartedPage />,
			},
			{
				path: "/savedby-billing",
				element: <BillingPage />,
			},
			{
				path: "/installing-savedby",
				element: <InstallingPage />,
			},
			{
				path: "/savedby-widget",
				element: <WidgetPage />,
			},
			{
				path: "/savedby-claims",
				element: <ClaimsPage />,
			},
			{
				path: "/merchant-dashboard",
				element: <MerchantDashboardPage />,
			},
			{
				path: "/klaviyo-integration",
				element: <KlaviyoIntegrationPage />,
			},
			{
				path: "/exclude-savedby-product-from-review-emails",
				element: <ExcludeProductsPage />,
			},
			{
				path: "/customer-faq",
				element: <CustomerFAQPage />,
			},
		],
	},
	{
		path: "*",
		element: <Navigate replace to="/" />,
	},
];
