import React from "react";
import Container from "../components/Container";
import { customerFAQPageContent } from "../utils/constant";
import RenderPageData from "../components/RenderPageData";
import { Helmet, HelmetProvider } from "react-helmet-async";

const CustomerFAQ = () => {
	const pageTitle = "Customer FAQs";

	return (
		<Container>
			<HelmetProvider>
				<Helmet>
					<title>{pageTitle}</title>
				</Helmet>
			</HelmetProvider>
			<RenderPageData pageData={customerFAQPageContent} pageTitle={pageTitle} />
		</Container>
	);
};

export default CustomerFAQ;
