import React from "react";
import Container from "../components/Container";
import { excludeProductsPageContent } from "../utils/constant";
import RenderPageData from "../components/RenderPageData";
import { Helmet, HelmetProvider } from "react-helmet-async";

const ExcludeProducts = () => {
	const pageTitle = "Exclude SavedBy Product from Review Emails";

	return (
		<Container>
			<HelmetProvider>
				<Helmet>
					<title>{pageTitle}</title>
				</Helmet>
			</HelmetProvider>
			<RenderPageData pageTitle={pageTitle} pageData={excludeProductsPageContent} />
		</Container>
	);
};

export default ExcludeProducts;
