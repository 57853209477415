import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import { alpha, useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";

import { ThemeModeToggler } from "./components";

const Topbar = ({ onSidebarOpen }) => {
	const theme = useTheme();

	return (
		<Box
			display={"flex"}
			justifyContent={"space-between"}
			alignItems={"center"}
			width={1}
		>
			<Box >
				<Link underline="none" component="a" href="/" color="text.primary">
					<Typography fontWeight={600} variant={"h5"}>
            SavedBy Help Center
					</Typography>
				</Link>
			</Box>
			<Box sx={{ display: { xs: "none", md: "flex" } }} alignItems={"center"}>
				<Box marginLeft={3}>
					<ThemeModeToggler />
				</Box>
			</Box>
			<Box sx={{ display: { xs: "flex", md: "none" } }} alignItems={"center"}>
				<Box marginRight={1}>
					<ThemeModeToggler />
				</Box>
				<Button
					onClick={() => onSidebarOpen()}
					aria-label="Menu"
					variant={"outlined"}
					sx={{
						borderRadius: 2,
						minWidth: "auto",
						padding: 1,
						borderColor: alpha(theme.palette.divider, 0.2),
					}}
				>
					<MenuIcon />
				</Button>
			</Box>
		</Box>
	);
};

Topbar.propTypes = {
	onSidebarOpen: PropTypes.func,
};

export default Topbar;
