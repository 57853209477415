export const email = "support@savedby.io";
export const devEmail = "dev@savedby.io";
export const claimsEmail = "claims@savedby.io";
export const merchantStatement = "https://merchant.savedby.io/statements";
export const merchantClaims = "https://merchant.savedby.io/claims";
export const merchantDashboard = "https://merchant.savedby.io";
export const fileClaim = "https://savedby.io/file-a-claim";
export const savedByPolicies = "https://savedby.io/policies";
export const shopifyAppLink = "https://apps.shopify.com/savedby";
export const callBooking = "https://calendly.com/savedby-merchant-success/onboarding-call";
export const merchantUser = "https://merchant.savedby.io/users";
export const yotpoSupport = "https://support.yotpo.com/en/article/adding-products-to-blocklist";
export const stampedsupport = "https://stampedsupport.zendesk.com/hc/en-us/articles/9769025624475-Exclude-Products-from-Review-Request-Emails";
export const helpLoox = "https://help.loox.io/article/25-how-do-i-blacklist-customers-products-or-orders-from-review-request-emails";
export const judgeMeSupport =
	"https://support.judge.me/support/solutions/articles/44001782384-blacklist-specific-customers-product-or-orders-from-receiving-review-requests#:~:text=Exclude%20products,-Available%20on%20the&text=To%20stop%20sending%20review%20requests,Send%20request%20status%20to%20NO.";
