import React from "react";
import Container from "../components/Container";
import { billingPageContent } from "../utils/constant";
import RenderPageData from "../components/RenderPageData";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Billing = () => {
	const pageTitle = "SavedBy Billing Process";

	return (
		<Container>
			<HelmetProvider>
				<Helmet>
					<title>{pageTitle}</title>
				</Helmet>
			</HelmetProvider>
			<RenderPageData pageTitle={pageTitle} pageData={billingPageContent} />
		</Container>
	);
};

export default Billing;
