import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export default function RenderPageData({ pageData, pageTitle }) {
	return (
		<Stack spacing={{ xs: 4, sm: 3 }}>
			<Box>
				<Typography
					variant="h4"
					mb={2}
					gutterBottom
					sx={{
						fontWeight: 700,
					}}
				>
					{pageTitle}
				</Typography>
			</Box>
			{pageData &&
				pageData?.map((item, index) => {
					return (
						<Box key={index}>
							<Typography fontWeight={600} variant={"h6"} mb={1}>
								{item.heading}
							</Typography>
							{item?.description.map((descriptionItem, descriptionIndex) => {
								return <Typography mb={0.5} key={descriptionIndex} dangerouslySetInnerHTML={{ __html: descriptionItem }} />;
							})}
						</Box>
					);
				})}
		</Stack>
	);
}
