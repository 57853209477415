import {
	email,
	claimsEmail,
	merchantStatement,
	callBooking,
	merchantClaims,
	shopifyAppLink,
	merchantDashboard,
	fileClaim,
	savedByPolicies,
	merchantUser,
	yotpoSupport,
	stampedsupport,
	helpLoox,
	judgeMeSupport,
} from "./contactLinks";

import image1 from "../assets/images/excludeProductPage/image1.png";
import image2 from "../assets/images/excludeProductPage/image2.png";
import image3 from "../assets/images/excludeProductPage/image3.png";
import image4 from "../assets/images/excludeProductPage/image4.png";

export const widgetPageContent = [
	{
		heading: "What Are My Options?",
		description: [
			"We offer the widget either on your:",
			"<br><b>Storefront:</b> Think of this as your side cart or cart page.<br />",
			"<b>Checkout:</b> Think of this as once your customer clicks “checkout” and begins to fill in their information.",
			"<br>Shopify+ Merchants have the option of either of these locations, but we default to both places where possible.",
			"<br>Non-Shopify+ Merchants only have the option of the storefront due to Shopify’s Limitation of editing the checkout process until a store has upgraded to Shopify+.",
		],
	},
	{
		heading: "Can I Change The Location Of The Widget On My Store?",
		description: [`Sure! Please reach out to your Merchant Success Manager, or to our team at <a href='mailto:${email}'>${email}</a>.`],
	},
	{
		heading: "How Do Tiers Work?",
		description: [
			"The SavedBy widget will adjust the price of the SavedBy product automatically based on the value of the items in the cart. The widget shows the price of SavedBy, and when items are added or removed from the cart, the widget will update to reflect this change.",
			`<br>Would you like a breakdown of the tiers? Please reach out to your Merchant Success Manager, or to our team at <a href='mailto:${email}'>${email}</a>.`,
		],
	},
	{
		heading: "I Have Shopify’s New One Page Checkout, Can I Still Use SavedBy’s Widget?",
		description: ["Yes! We have partnered with Shopify to have a seamless checkout experience even with the one-page checkout."],
	},
	{
		heading: "Can My Dev Team Copy Over The Widget To Our New Theme?",
		description: [
			`You can either copy the code yourself, or if you prefer, reach out to our team and we’d be more than happy to make sure the integration goes smoothly. Please reach out to your Merchant Success Manager, or to our team at <a href='mailto:${email}'>${email}</a> for help with this or any other questions about theme changes.`,
		],
	},
];

export const billingPageContent = [
	{
		id: "1",
		heading: "I Thought SavedBy Was a Free App?",
		description: [
			"SavedBy is a completely free app for you, the merchant, to use. SavedBy offers your customers the option to purchase package protection during the Checkout Process. When a customer chooses to purchase SavedBy, the SavedBy Package Protection Product is added to their cart, and becomes part of their order. You, the merchant, collects this SavedBy amount. Think of it as a cash advance.",
			"<br>At the end of the month, we will request the SavedBy amount that you collected to be remitted back to us. You are not paying us for the service, rather you are transferring the money collected from your customers over to us.",
		],
	},
	{
		id: "2",
		heading: "Why Doesn’t SavedBy Collect Directly From My Customers?",
		description: [
			"SavedBy doesn’t have access to your customers’ payment information. This is sensitive information and is protected by Shopify and / or your payment processor. Additionally, by having the customer purchase SavedBy within their transaction, the checkout process remains seamless for both your customer and you!",
		],
	},
	{
		id: "3",
		heading: "How Do I Get Paid For Reorder, Refunds, etc?",
		description: [
			"SavedBy will calculate the total amount of SavedBy your store has collected for a month. Let’s say this amount is $120.",
			"<br>Then, SavedBy will accumulate all of the refunds, reorders, etc throughout the month to get a total amount of claims processed for your customers. Let’s say this amount is $100.",
			"<br>Finally, SavedBy will deduct the claims amount from the total amount that your store collected, for that month. This will be the amount remitted. In this case, it would be $120 - $100 = $20 remittance.",
			"<br>We deduct the amount of claims from the total amount collected because SavedBy does not physically pay for the reorders placed at the time of reordering, instead we manually mark as paid within your store, and at the end of the month we will pay for these orders through the process above. Additionally, SavedBy will not pay for refunds at the time of refunding, these refunds processed will come out of your bank account and go to your customer. At the end of the month, we will pay for these claims by paying you directly via this above remittance process.",
		],
	},
	{
		id: "4",
		heading: "Where Can I See Statements / History of Remittances?",
		description: [`You can see all of this information in your Merchant Dashboard Here: <a href='${merchantStatement}' target='_blank'>${merchantStatement}</a>.`],
	},
	{
		id: "5",
		heading: "How Do I Get Billed?",
		description: [
			`You can track all of the claims associated with your store in the Merchant Dashboard Here: <a href='${merchantClaims}' target='_blank'>${merchantClaims}</a>.`,
			"<br>Alternatively, we can offer other methods of remittance, such as ACH, Credit Card, and Checks, if you prefer. Reach out to us to learn more!",
		],
	},
	{
		id: "6",
		heading: "Where Can I Track Claims?",
		description: [
			`You can see all of this information in your Merchant Dashboard Here: <a href='${merchantStatement}' target='_blank'>${merchantStatement}</a>.`,
			`<br>Want to learn more about the claims process? <a href='/savedBy-claims'>View our Help Article Here</a>.`,
		],
	},
	{
		id: "7",
		heading: "How Do I Contact The SavedBy Team With Questions?",
		description: [`Have questions for the SavedBy Team? Reach out to us at <a href='mailto:${email}'>${email}</a>.`],
	},
];

export const claimsPageContent = [
	{
		id: "1",
		heading: "How Our Claims Process Works:",
		description: [
			"When a package is Lost, Damaged, Stolen, or Returned to Sender, SavedBy is here to help. Customer’s can file a claim with us, and our CS team will get back to them and resolve the claim that same day.",
		],
	},
	{
		id: "2",
		heading: "How Customers File Claims:",
		description: [
			`Customers will file a claim with us by using this link: <a href='${fileClaim}' target='_blank'>${fileClaim}</a>. All they need to provide is their email address, order number, and some basic information regarding their issue, and we will take it from there.`,
		],
	},
	{
		id: "3",
		heading: "How Do Customers Know Where and How To File:",
		description: [
			"SavedBy will send a confirmation email after they purchase from your store with the subject line of “Your *Merchant Name* Order is Protected!”. This email will have a link to file a claim directly. Additionally, we will provide an email template that your team can use when a customer reaches out to you about a package issue, and this template has a link to our file a claim page as well.",
			`<a href='mailto:${email}?subject=SavedBy Claim - {{ Merchant Name }} {{ Order Number }}&body=Hi {{Customer Name}}!%0d%0a%0d%0aI am so sorry to hear that you are having issues with your package, but the good news is that the SavedBy package protection you purchased when you ordered will be able to assist you - Please reach out to SavedBy ASAP. Just look up this email subject line in your inbox: “Your {{Merchant Name}} Order is Protected!” to make sure you’re within the claim policy window - You can also find the Claim Policies here: https://savedby.io/policies.%0d%0a%0d%0aIf you meet the requirements, please fill out the claim form on their website here: https://savedby.io/file-a-claim - Please provide a thorough explanation of your situation in order to have it resolved as soon as possible!%0d%0a%0d%0aThank you for your patience,%0d%0a{{Customer Service Representative’s Name}}
      ' target='_blank'>Email Template For When A Customer Has An Issue and Purchased SavedBy</a>.`,
		],
	},
	{
		id: "4",
		heading: "The Customer Filed a Claim, Now What?:",
		description: [
			"Our team will take it from here! We will reach out to the customer, learn more about their issue, and offer to either resend or refund the order. Our team will go ahead and place a new order in your store, or refund the order for the customer. There’s nothing that you’ll need to do once a claim has been filed.",
		],
	},
	{
		id: "5",
		heading: "How To Track The Claim Process:",
		description: [
			`You can view all of the claims, and their statuses, in our Merchant Dashboard here: <a href='${merchantClaims}' target='_blank'>${merchantClaims}</a>. We provide notes regarding the issue the customer stated, the current status of the claim, and information from our team as well. Just click into a claim on this dashboard, and you can see this information, and you can also book a time to meet with your Merchant Success Manager Directly.`,
		],
	},
	{
		id: "6",
		heading: "The Package Is A Return To Sender, Or The Customer Refuses To File A Claim:",
		description: [
			`You can go in and file a claim on behalf of a customer. If you go to <a href='${merchantClaims}' target='_blank'>${merchantClaims}</a>, and click on “Create Claim” in the top right corner, you can enter in the order #, reason for the claim, and a brief description. Our team will take it from there by reaching out to the customer and resolving the issue.`,
		],
	},
	{
		id: "7",
		heading: "Claim Status: Closed versus Denied. What’s the Difference?:",
		description: [
			"A closed claim is one that has been resolved without the need for SavedBy. For example: if a customer files a claim, but then subsequently receives their package without needing SavedBy to resend one, we will mark this as closed.",
			"<br>A denied claim is one that we have determined does not fall within our policies. For example: if a customer places an order, and then reaches out to SavedBy 12 months after the delivery date stating they never received their order. This would be outside of our policy window and denied.",
		],
	},
	{
		id: "8",
		heading: "I Don’t Agree With SavedBy’s Decision On A Claim:",
		description: [
			`We are more than happy to take a second (or third) look at any claim processed. Please reach out to either your Merchant Success Manager or our team at <a href='mailto:${email}'>${email}</a>.`,
		],
	},
	{
		id: "9",
		heading: "How Do I Know When SavedBy Places A New Order, Or Initiates A Refund?:",
		description: [
			"We will add a tag to all orders that are processed by SavedBy. For reorders, we will tag the new order with “SavedBy Resend”, and for refunds we will tag these with “SavedBy Refund”. This allows for easy tracking in your system, and can help your team prioritize which orders to send out first.",
		],
	},
	{
		id: "10",
		heading: "What Happens If The Item For Reorder Is Out Of Stock?:",
		description: [
			"Our team makes sure to check the inventory level of items before placing a replacement order. If an item is out of stock, we will offer the customer a different item, a gift card, or a refund.",
		],
	},
	{
		id: "11",
		heading: "Can A Customer Change The Address On A Reorder?:",
		description: ["Yes! As long as it’s within the same country, that’s no problem."],
	},
	{
		id: "12",
		heading: "Where Our Policies Are Located:",
		description: [`Our policies are located on our website here: <a href='${savedByPolicies}' target='_blank'> SavedBy Policies</a>.`],
	},
	{
		id: "13",
		heading: "How To Reach Out To The SavedBy Team Directly:",
		description: [`Have questions for the SavedBy Team? Reach out to us at <a href='mailto:${email}'> ${email}</a>.`],
	},
];

export const installingProcessPageContent = [
	{
		id: "1",
		heading: "Installing SavedBy Package Protection",
		description: [
			`Ready to get started protecting your packages? Go to our <a href='${shopifyAppLink}' target='_blank'> Shopify App Here</a> and click “Add App”. Once you download the app, you will be directed to our <a href='${merchantDashboard}' target='_blank'> Merchant Dashboard.</a>`,
			"<br>Once you are at our Dashboard, you will be prompted to create an account. Please enter in your email address, create a password, and click “create account”. Don’t worry - you can always change your password later and make accounts for other users later.",
			`<br>After installing, nothing will change in your Shopify Store. To get up and running, book a call with us! You can do so by emailing: <a href='mailto:${email}'>${email}</a>. or by <a href='${callBooking}' target='_blank'> booking a call here</a>. This onboarding call only takes about 20 minutes.`,
			"<br>On this call we will go over everything you need to know about SavedBy, assign a Merchant Success Manager to your account, and plan to be live within 24 hours. During this onboarding time our Dev team will: manually onboard our widget onto your store, set up email confirmations for your customers, and go through a thorough QA process.",
		],
	},
];

export const gettingStartedPageContent = [
	{
		id: "1",
		heading: "Where Is The Merchant Dashboard? And What’s It Used For?",
		description: [
			"The Merchant Dashboard is the best place for your team to review claims, schedule a call with your Merchant Success Manager, add team members to your Merchant Dashboard, and review remittance statements.",
			`<br>You can view your <a href='${merchantDashboard}' target='_blank'> Merchant Dashboard Here </a>.`,
		],
	},
	{
		id: "2",
		heading: "I Finished My Onboarding Call, Where Can I Find More Information?",
		description: [
			"Your Merchant Success Manager will have sent over a post-onboarding email with a lot of information. This email should be titled “*Merchant Name* x SavedBy - Post Onboarding Call”. Refer to this email for general information, documents such as email templates, and any specific information you had discussed on the call.",
			"<br>Can’t find this email? Please see these links for some general information:",
			`<a href='/customer-faq'>Customer FAQs</a>`,
			`<a href='mailto:${email}?subject=SavedBy Claim - {{ Merchant Name }} {{ Order Number }}&body=Hi {{Customer Name}}!%0d%0a%0d%0aI am so sorry to hear that you are having issues with your package, but the good news is that the SavedBy package protection you purchased when you ordered will be able to assist you - Please reach out to SavedBy ASAP. Just look up this email subject line in your inbox: “Your {{Merchant Name}} Order is Protected!” to make sure you’re within the claim policy window - You can also find the Claim Policies here: https://savedby.io/policies.%0d%0a%0d%0aIf you meet the requirements, please fill out the claim form on their website here: https://savedby.io/file-a-claim - Please provide a thorough explanation of your situation in order to have it resolved as soon as possible!%0d%0a%0d%0aThank you for your patience,%0d%0a{{Customer Service Representative’s Name}}
      ' target='_blank'>Email Template For When A Customer Has An Issue and Purchased SavedBy</a>`,
		],
	},
	{
		id: "3",
		heading: "My Customers Purchased SavedBy - Now What?",
		description: [
			"Congrats! You're protecting your packages, earning revenue, increasing CX, and reducing costs.",
			"<br>Your customer will receive a Branded Email Confirmation right after they checkout from your store. The confirmation email will look something like this. Within this email, your customers will be able to view our policies, file a claim, and reach out to our team directly for any questions.",
			`<br>If a customer has an issue with their package, they will be able to file a claim directly with us through our website here:<a href='${fileClaim}' target='_blank'> ${fileClaim}</a>. Once a claim is filed, we aim to resolve the issue, and either resend a package or refund your customer, within 24 hours!`,
		],
	},
	{
		id: "4",
		heading: "I Have a Question For The SavedBy Team",
		description: [
			`You can either reach out directly to your Merchant Success Manager, or feel free to send us an email to  <a href='mailto:${email}'>${email}</a>.`,
			`<br>You can view your <a href='${merchantDashboard}' target='_blank'> Merchant Dashboard Here </a>.`,
		],
	},
	{
		id: "5",
		heading: "I Want To Learn More About",
		description: [
			"- <a href='/installing-savedby'> Installing</a>",
			"- <a href='/savedby-billing'>Billing </a>",
			"- <a href='/savedby-claims'>Claims </a>",
			"- <a href='/exclude-savedby-product-from-review-emails'>Excluding SavedBy From Review Emails </a>",
		],
	},
];

export const merchantDashboardPageContent = [
	{
		id: "1",
		heading: "What Is The Merchant Dashboard?",
		description: ["This is where you, and any member of your team that you add to the dashboard, can view claims, statements, users, and account information."],
	},
	{
		id: "2",
		heading: "Where Is The Merchant Dashboard",
		description: [
			"The Merchant Dashboard can be found either by going to:",
			"<br>Your Shopify Dashboard > Apps > SavedBy Package Protection",
			"OR",
			`<a href='${merchantDashboard}' target='_blank'>${merchantDashboard}</a>`,
		],
	},
	{
		id: "3",
		heading: "How Do I Control Permissions For Our Users?",
		description: [
			`Go to the “Users” section within the Merchant Dashboard, or click this link: <a href='${merchantUser}' target='_blank'>${merchantUser}</a>`,
			"<br>From here, you can select a user and go to the “Permissions” section. Select or Unselect different permissions. When a permission is selected, this allows the user to see the related tab within the dashboard. Most CS Users will only need the “Claims” view.",
			"<br>From this Users section you can also: create new users, delete users, and send password reset links to users.",
		],
	},
	{
		id: "4",
		heading: "How Do I View Claims?",
		description: [
			`Go to the “Claims” section within the Merchant Dashboard, or click this link: <a href='${merchantClaims}' target='_blank'>${merchantClaims}</a>`,
			`<br>From here, you will see a list of all of your claims. These include those that have been submitted by your customers and from your team.`,
			`<br>You can click quickly review claims’ statuses, last updates, order # the claims are related to, and the date the claim was submitted.`,
			`<br>To view additional information, you can click into the claim itself to view information such as: timeline of the claim (including notes from our team), claim info (information provided by the customer), items in the order, and a link to book a call with your Merchant Success Manager for any questions about the claim.`,
		],
	},
	{
		id: "5",
		heading: "How Do I Submit A Claim?",
		description: [
			`If you want to submit a claim on behalf of your customer (very common for Return To Sender Packages where your customer may not realize it was returned), you can do so by going to the “Claims” section within the Merchant Dashboard, or click this link: <a href='${merchantClaims}' target='_blank'>${merchantClaims}</a>.`,
			"<br>From there, look at the top right of the page where it says “Create Claim”. Just click that button, enter in the order #. Once you enter the order # this will prompt another page which you just need to enter in the reason for the claim and any other information that will help our team.",
			`<br>When you attempt to submit your claim, is the order outlined in red and unclickable? This means that the order did not have SavedBy on it, and is not able to be submitted to our team.`,
		],
	},
	{
		id: "6",
		heading: "Have Questions?",
		description: [`Feel free to reach out to your Merchant Success Manager, or our support team: <a href='mailto:${email}'>${email}</a>.`],
	},
];

export const excludeProductsPageContent = [
	{
		id: "1",
		heading: "How To Exclude The SavedBy Product From Your Review Emails",
		description: [
			"Are you looking to exclude the SavedBy Product from your review request emails? You’re in the right place! Select your Review Provider below for a step-by-step guide.",
			`<br/><b>Yotpo</b>`,
			` - If you’d like to block the SavedBy Product from your Review Emails from Yotpo, follow these steps:`,
			`1) Find the SavedBy Product in Shopify: Go to your Products list in Shopify, and search “SavedBy”. Click into the SavedBy Package Protection product.`,
			`<br/><img src='${image3}' width="100%">`,
			`2) Add the “YBlocklist” tag: Scroll down to the Tags section of the product, and type in <code>YBlocklist</code>.`,
			`<br/><img class='export-product-image' src='${image1}' width="100%"><br/><br/>`,
			`3) All set! SavedBy will now not be shown in your Product Review Emails. Need more help? Go directly to <a href='${yotpoSupport}' target='_blank'>Yotpo.com Support Here</a> `,
			`<b><br/>Stamped.io</b>`,
			` - If you’d like to block the SavedBy Product from your Review Emails from Stamped, follow these steps:`,
			`1) Set up Exclusion Tag in Stamped: Go to your Stamped.io App. From there, go to Settings, and scroll down to the “Exclude Products from Review Request” section. Enter in a desired tag, such as “exclude-review”.`,
			`2) Find the SavedBy Product in Shopify: Go to your Products list in Shopify, and search “SavedBy”. Click into the SavedBy Package Protection product.`,
			`<br/><img  src='${image3}' width="100%">`,
			`3) Add the “exclude-review” tag: Scroll down to the Tags section of the product, and type in <code>exclude-review</code>.`,
			`<br/><br/><img class='export-product-image' src='${image4}' width="100%"><br/>`,
			`4) All set! SavedBy will now not be shown in your Product Review Emails. Need more help? Go directly to <a href='${stampedsupport}' target='_blank'> Stamped.io Support Here</a>`,
			`<br/><b>Loox</b>`,
			` - If you’d like to block the SavedBy Product from your Review Emails from Loox, follow these steps:`,
			`1) Find the SavedBy Product in Shopify: Go to your Products list in Shopify, and search “SavedBy”. Click into the SavedBy Package Protection product.`,
			`<br/><img src='${image3}' width="100%">`,
			`2) Add the “exclude-review” tag: Scroll down to the Tags section of the product, and type in <code> NOREVIEW </code>.`,
			`<br/><img class='export-product-image' src='${image2}' width="100%"><br/>`,
			`3) All set! SavedBy will now not be shown in your Product Review Emails. Need more help? Go directly to <a href='${helpLoox}' target='_blank'> Loox.io Support Here</a>.`,
			`<br/><b>Judge.me</b>`,
			`- If you’d like to block the SavedBy Product from your Review Emails from Judge.me, follow these steps:`,
			`1) Change the Status of SavedBy within Judge.me: Go to the Admin section within Judge.me. Go to Manage Reviews, and then to Products & Groups. From there find the SavedBy product and change its Send Request status to No`,
			`2) All set! SavedBy will now not be shown in your Product Review Emails. Need more help? Go directly to <a href='${judgeMeSupport}' target='_blank'> Judge.me Support Here</a>.`,
		],
	},
];

export const customerFAQPageContent = [
	{
		id: "1",
		heading: "What Is SavedBy Package Protection?",
		description: [
			"SavedBy Package Protection is an optional add-on that can help cover the cost of lost, damaged or stolen packages. We do everything that we can to make sure the package gets to you, but in cases when issues arise SavedBy can help.",
		],
	},
	{
		id: "2",
		heading: "How Much Does It Cost?",
		description: ["SavedBy varies depending on the price of your order. It will automatically calculate once you begin to add items to your cart."],
	},
	{
		id: "3",
		heading: "Do I Need SavedBy?",
		description: [
			"You don’t, but it can help speed up the process of resolving issues related to shipping. And in some cases where packages may not be covered by us, SavedBy can help where we wouldn’t be able to.",
		],
	},
	{
		id: "4",
		heading: "How Do I Use My SavedBy Protection?",
		description: [
			`If you have issues with your package, reach out to SavedBy directly here: <a href='${fileClaim}' target='_blank'>${fileClaim}</a>. Please review their policies to make sure that your package can be covered here: <a href='${savedByPolicies}' target='_blank'>${savedByPolicies}</a>.`,
		],
	},
	{
		id: "5",
		heading: "How Long Do I Have To Wait Until I Can Receive A New Package?",
		description: ["SavedBy can help send a new package within 1 business day! Sometimes it may take more, but in most cases they are very quick to resolve any issues that arise."],
	},
	{
		id: "6",
		heading: "Will SavedBy Reach Out To Me Directly?",
		description: [
			"SavedBy will send you an email confirmation once you have placed an order in our store that includes their SavedBy Package Protection. Keep your eye out for it!",
			`<br>If you have any questions for their team, or have filed a claim, they will email you directly with any questions, updates, and resolutions. Their email address is: <a href='mailto:${email}'>${email}</a> for general questions, and <a href='mailto:${claimsEmail}'>${claimsEmail}</a>`,
		],
	},
];

export const pages = [
	{
		groupTitle: "Getting Started With SavedBy",
		id: "started",
		pages: [
			{
				title: "Getting Started",
				href: "/",
			},
			{
				title: "Installing SavedBy",
				href: "/installing-savedby",
			},
			{
				title: "SavedBy Billing Process",
				href: "/savedby-billing",
			},
			{
				title: "SavedBy Claims Process",
				href: "/savedby-claims",
			},
			{
				title: "Excluding SavedBy From Review Emails",
				href: "/exclude-savedby-product-from-review-emails",
			},
			{
				title: "Merchant Dashboard",
				href: "/merchant-dashboard",
			},
			{
				title: "SavedBy Widget",
				href: "/savedby-widget",
			},
			{
				title: "Customer FAQs",
				href: "/customer-faq",
			},
		],
	},

	{
		groupTitle: "Integrations",
		id: "integrations",
		pages: [
			{
				title: "Klaviyo",
				href: "/klaviyo-integration",
			},
		],
	},
];
