import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { vs2015 } from "react-syntax-highlighter/dist/cjs/styles/hljs";
import { useTheme } from "@mui/material/styles";
import { devEmail, merchantDashboard } from "../utils/contactLinks";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import image1 from "./../assets/images/klaviyoIntegration/image1.png";
import image2 from "./../assets/images/klaviyoIntegration/image2.png";

import Container from "../components/Container";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Klaviyo = () => {
	const pageTitle = "Klaviyo Integration";

	const theme = useTheme();
	const renderCodeBlock = (code = "", language = "javascript") => {
		return (
			<Box component={SyntaxHighlighter} language={language} style={vs2015} padding={`${theme.spacing(2)} !important`} borderRadius={2} margin={`${theme.spacing(0)} !important`}>
				{code}
			</Box>
		);
	};

	return (
		<Container>
			<HelmetProvider>
				<Helmet>
					<title>{pageTitle}</title>
				</Helmet>
			</HelmetProvider>
			<Stack spacing={{ xs: 4, sm: 8 }}>
				<Box>
					<Typography
						variant="h4"
						mb={2}
						gutterBottom
						sx={{
							fontWeight: 700,
						}}
					>
						{pageTitle}
					</Typography>
					<Typography fontWeight={700} variant={"h6"} mb={2}>
						How to Hide The SavedBy Package Protection Product From Your Klaviyo Emails (Abandoned Checkout and Order Confirmation)
					</Typography>
					<Typography mb={2}>The SavedBy x Klaviyo integration allows for replacing the original Shopify event triggers, such as order creation and checkout abandonment, but removes the SavedBy product from the line items and adds a few new properties, like SavedByPrice and hasSavedBy.</Typography>
					<Typography fontWeight={700} mb={2}>
						Connect Klaviyo to SavedBy
					</Typography>
					<img className="klaviyo-images" src={image1} alt="Connect Klaviyo" />
					<Typography mt={2} mb={0.5}>
						1. Log in to your{" "}
						<a href="https://www.klaviyo.com/login" target="_blank" rel="noreferrer">
							Klaviyo
						</a>{" "}
						account and navigate to <b>Settings &gt; Account &gt; API Keys</b>. Settings can be found in the bottom left-hand corner in the pop-up menu that appears when you click on your account. Then click the Account tab, followed by API keys, which is listed on the side menu of the <b>Settings &gt; Account</b> page.
					</Typography>
					<Typography mb={0.5}>
						2. Create a private API key by clicking the <b>&quot;Create Private API Key&quot;</b> button.
					</Typography>
					<Typography mb={0.5}>
						3. Name this &quot;SavedBy&quot; and grant &quot;<b>Full Access</b>
						&quot; to &quot;<b>Events</b>&quot;. Confirm the settings by clicking “Create” at the top right. Please refer to the above image.
					</Typography>
					<Typography mb={0.5}>
						4. On the resulting page, copy the API key and paste it on the SavedBy integration text field labeled “API KEY” which is located on the{" "}
						<a href={merchantDashboard} target="_blank" rel="noreferrer">
							Merchant Portal
						</a>{" "}
						Integrations page.
					</Typography>
					<Typography mb={0.5}>
						5. If you are having trouble finding the &quot;API KEY&quot; text field, follow these steps: Log in to the{" "}
						<a href={merchantDashboard} target="_blank" rel="noreferrer">
							Merchant Portal
						</a>
						, click &quot;Integrations&quot; on the side bar menu. Once you are on the Integrations page, click the &quot;LEARN MORE&quot; button on the Klaviyo card and then you can paste your SavedBy API key into the textfield located under the &quot;Setup Instructions&quot;.
					</Typography>
					<Typography mb={0.5}>
						6. On the Klaviyo Integration card on the{" "}
						<a href={merchantDashboard} target="_blank" rel="noreferrer">
							Merchant Portal
						</a>{" "}
						under <b>“Flow Triggers”</b>, select which event trigger(s) you would like sent to Klaviyo.
					</Typography>
				</Box>

				<Box>
					<Typography fontWeight={700} mb={1}>
						Set up your current flows to use SavedBy’s triggers
					</Typography>
					<img className="klaviyo-images" src={image2} alt="Setup flow" />
					<Typography mt={2} mb={0.5}>
						1. Navigate to Flows and clone your existing “<b>Checkout Started</b>” or “<b>Order Placed</b>” triggered flow(s).
					</Typography>
					<Typography mb={0.5}>
						2. Replace the <b>Trigger</b> with the same trigger with “(SavedBy)” prepended. For Example, replace “Placed Order” metric with “Placed Order (SavedBy)”.
					</Typography>
				</Box>
				<Box>
					<Typography fontWeight={600} mb={10}>
						For more information, visit{" "}
						<a href="https://help.klaviyo.com/hc/en-us" target="_blank" rel="noreferrer">
							Klaviyo Help documentation
						</a>
					</Typography>
					<Typography variant="h5" fontWeight={700} mb={2}>
						Tech Speak
					</Typography>
					<Typography fontWeight={400} mb={2}>
						Event properties that are added to the event object include <b>hasSavedBy</b> and <b>savedByPrice</b>. Examples for each object are shown below.
					</Typography>
					<Typography fontWeight={600} mb={2}>
						Order Placed
					</Typography>
					<Box mb={2}>
						{renderCodeBlock(`
{
  Items:['ITEM_TITLE'],
  Collections: [], // THIS WILL ALWAYS BE AN EMPTY ARRAY
  'Item Count': 2,
  'Discount Codes': [ 'DISCOUNT_CODE' ],
  'Source Name': '<SOURCE_NAME>',
  '$currency_code': 'USD',
  '$event_id': <ORDER_ID>,
  '$value': 35.99,
  '$extra': {
    referring_site: '<REFERRER_URL>',
    full_landing_site: '<SHOPIFY_DOMAIN>',
    token: '********************',
    webhook_topic: 'order/created',
    presentment_currency: 'USD',
    note_attributes: [],
    line_items: [ 
      {
          discountTotal: '16.99',
          discounts: [
            {
              amount: '16.43',
              value: '35.0',
              valueType: 'percentage',
              valueSuffix: '%',
              code: 'DISCOUNT_CODE'
            }
          ],
          fulfillmentStatus: null,
          fulfillmentCount: 0,
          refundedCount: 0,
          lineItemId: 99999999999999,
          id: 9999999999999,
          title: '<PRODUCT_TITLE>',
          variantTitle: '<VARIANT_TITLE>',
          originalPrice: '84.95',
          price: '67.96',
          quantity: 1,
          shippable: true,
          tax: '4.59',
          totalPrice: '72.55',
          variantId: 99999999999999,
          vendor: '<MERCHANT>',
          product: {
          id: 9999999999999,
          title: '<PRODUCT_TITLE>',
          body_html: '<HTML>',
          vendor: '<MERCHANT>',
          product_type: '',
          created_at: '2022-10-28T15:45:13-04:00',
          handle: '<PRODUCT_HANDLE>',
          updated_at: '2023-06-30T13:50:56-04:00',
          published_at: '2022-10-28T15:46:46-04:00',
          template_suffix: '',
          status: 'active',
          published_scope: '<PUBLISHED_SCOPE>',
          tags: [ 'TAGS' ],
          admin_graphql_api_id: '<GRAPHQL_API_ID>',
          image: {
            id: 9999999999999,
            product_id: 999999999999,
            position: 1,
            created_at: '2022-10-28T15:46:02-04:00',
            updated_at: '2022-10-28T15:46:02-04:00',
            alt: null,
            width: 1080,
            height: 1080,
            src: '<IMAGE_URL>',
            variant_ids: [],
            admin_graphql_api_id: '<GRAPHQL_API_ID>',
          },
      },
    ],
  },
  hasSavedBy: true,
  items_subtotal: 27.81,
  savedByPrice: 1.36,
  'Total Discounts': 25.97 
}`)}
					</Box>
					<Typography fontWeight={600} mb={2}>
						Checkout Started
					</Typography>
					<Box mb={2}>
						{renderCodeBlock(`
{
  Items: [ 'ITEM_TITLE' ],
  Collections: [],
  'Item Count': 1,
  'Discount Codes': [ 'DISCOUNT_CODE' ],
  'Source Name': null,
  '$currency_code': 'USD',
  '$event_id': <ORDER_ID>,
  '$value': 60.14,
  '$extra': {
    referring_site: '',
    full_landing_site: '<SHOPIFY_DOMAIN>',
    token: '********************',
    webhook_topic: 'checkouts/update',
    responsive_checkout_url: '<RESPONSIVE_CHECKOUT_URL>',
    checkout_url: '<CHECKOUT_URL>',
    presentment_currency: 'USD',
    note_attributes: [],
    line_items: [
      {
        applied_discounts: [],
        discount_allocations: [
          {
            id: null,
            amount: '17.13',
            description: '<DISCOUNT_NAME>',
            created_at: null,
            application_type: 'DISCOUNT_CODE',
          },
        ],
        key: '<KEY>',
        destination_location_id: null,
        fulfillment_service: 'manual',
        gift_card: false,
        grams: '<WEIGHT>',
        origin_location_id: 9999999999999,
        presentment_title: '<PRESENTMENT_TITLE>',
        presentment_variant_title: '<PRESENTMENT_VARIANT_TITLE>',
        product_id: 9999999999999,
        properties: [],
        quantity: 1,
        requires_shipping: true,
        sku: '<PRODUCT_SKU>',
        tax_lines: [
          {
            position: 1,
            price: '0.06',
            rate: 0.029,
            title: [ 'STATE_TAX' ],
            source: '<TAX_SOURCE>',
            compare_at: 0.029,
            zone: null,
            channel_liable: false,
            identifier: null,
            tax_type: null,
            reporting_taxable_amount: '1.99',
            reporting_exempt_amount: '0.00',
            reporting_non_taxable_amount: '0.00',
            reporting_jurisdiction_code: null,
            reporting_jurisdiction_name: null,
            reporting_jurisdiction_type: null,
            jurisdiction_id: '<JURISDICTION_ID>',
            jurisdiction_type: '<STATE>',
            jurisdiction_source: '<JURISDICTION_TAX_SOURCE>',
          },
        ],
        taxable: true,
        title: '<PRODUCT_TITLE>',
        variant_id: 99999999999999,
        variant_title: '<VARIANT_TITLE>',
        variant_price: '49.95',
        vendor: '<MERCHANT>',
        user_id: null,
        unit_price_measurement: {
          measured_type: null,
          quantity_value: null,
          quantity_unit: null,
          reference_value: null,
          reference_unit: null,
        },
        rank: null,
        compare_at_price: '0.00',
        line_price: '49.95',
        price: '49.95',
        product: {
          id: 9999999999999,
          title: '<PRODUCT_TITLE>',
          body_html: '<HTML>',
          vendor: '<MERCHANT>',
          product_type: '<PRODUCT_TYPE>',
          created_at: '2018-12-23T16:33:46-05:00',
          handle: '<PRODUCT_HANDLE>',
          updated_at: '2023-06-29T23:02:01-04:00',
          published_at: '2020-04-25T21:09:09-04:00',
          template_suffix: '',
          status: "active",
          published_scope: '<PUBLISHED_SCOPE>',
          tags: [ 'TAGS' ],
          admin_graphql_api_id: '<GRAPHQL_API_ID>',
          image: {
            id: 99999999999999,
            product_id: 9999999999999,
            position: 1,
            created_at: '2023-05-03T08:48:46-04:00',
            updated_at: '2023-05-03T08:48:46-04:00',
            alt: null,
            width: 200,
            height: 200,
            src: '<IMAGE_URL>',
            variant_ids: [],
            admin_graphql_api_id: '<GRAPHQL_API_ID>',
          },
        },
      },
    ],
  },
  hasSavedBy: true,
  items_subtotal: 89.95,
  savedByPrice: 1.67,
  'Total Discounts': 31.48,
}`)}
					</Box>
					<Typography mb={0.5}>
						*If additional props are needed please reach out to <a href={"mailto:" + devEmail}>{devEmail}</a>.
					</Typography>
				</Box>
			</Stack>
		</Container>
	);
};

export default Klaviyo;
