import React from "react";
import Container from "../components/Container";
import { widgetPageContent } from "../utils/constant";
import RenderPageData from "../components/RenderPageData";
import { Helmet, HelmetProvider } from "react-helmet-async";

const WidgetPage = () => {
	const pageTitle = "SavedBy Widget";

	return (
		<Container>
			<HelmetProvider>
				<Helmet>
					<title>{pageTitle}</title>
				</Helmet>
			</HelmetProvider>
			<RenderPageData pageTitle={pageTitle} pageData={widgetPageContent} />
		</Container>
	);
};

export default WidgetPage;
